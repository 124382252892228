@import url(https://fonts.googleapis.com/css?family=Lato);
body {
    background: url(/images/night.jpg) repeat center center #1d1d1d;
    color: #eee;
    font-family: 'Lato', sans-serif, Arial;
    font-size: 13px;
    margin: 0 30%;
    max-height: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

.number {
    display: inline;
    float: left;
    margin-top: 20px;
    font-size: 100px;
}
.hgroup {
    display: block;
    text-align: center;
    bottom: 100px !important;
    clear: both;
}

h1 {
    color: #2e2b8d;
    -webkit-text-stroke: 1px #fff;
}

h2 {
    color: #fff;
    font-size: 18px;
    font-weight: normal;
    padding: 10px 0;
}

.icon {
    float: left;
    padding-top: 50px;
}

#animate {
    position: relative;
    text-align: center;
    margin: 0 auto;
    width: auto;
}

@media only screen and (min-width: 320px) and (max-width: 640px) {
    body {
        max-height: 100%;
        max-width: 100%;
        margin: 0 auto;
        overflow-x: hidden;
    }
}
